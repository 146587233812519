import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"
import CustomerJourney from "../../../components/customer-journey"
import PlanActionInAction from "../../../components/plan-action-in-action"
import Preview from "../../../components/preview"

const bannerContent = {
  title: "Customers leave. Churn busting dunning and retention stops them.",
  description: [
    {
      text:
        "Just like Billsby Checkout, our full account management stack can be added to your website with just two lines of code - you don't even need to build login and authentication unless you want to. It's the quickest account management going.",
    },
  ],
  buttons: [
    {
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      buttonText: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
  sectionFeaturetag: true,
  svg: true,
  image: require("../../../images/dunning-retention-header.svg"),
  imageName: "dunning-retention-header svg",
  backgroundColor: "#f3f5fb",
  textColor: "#000",
  imagePosition: "right",
  featureTagButton: true,
  tags: [
    {
      class: "adoric_dunning",
      text: "Dunning",
    },
    {
      class: "adoric_retention",
      text: "Retention",
    },
  ],
}

const customerJourneyContent = {
  title: "Stop failed payments in their tracks and recover revenue.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Build custom dunning flows",
      description:
        "Decide when to send reminder emails, when customers accounts will be suspended or cancelled and when to write-off unpaid invoices.",
    },
    {
      title: "Smart dunning understands why payments fail",
      description:
        "Don't treat customers whose cards decline the same way as those with technical difficulties - customise your dunning process based on decline reason codes.",
    },
    {
      title: "One-click reattempt",
      description:
        "Let your customers reattempt a payment in one-click from the dunning email - no need for logins or to remember the details of their account.",
    },
  ],
  svg: true,
  image: require("../../../images/stop.svg"),
  imageName: "stop",
  // bgPosition: true
  imagePosition: "bottom",
}

const dunningContent = {
  title: "Write dunning copy that works for your brand.",
  description: [
    {
      text:
        "With Billsby you can create unlimited products, plans and cycles and then manage them with unlimited team members before applying unlimited and discounts and coupons and selling them in unlimited countries using unlimited currencies with unlimited tax profiles.",
    },
    {
      text: "All with no monthly fees.",
    },
  ],
  backgroundImage: require("../../../images/typewriter.png"),
  image: "typewriter.png",
  imageName: "typewriter",
  backgroundColor: "#243f5c",
  textColor: "#fff",
  imagePosition: "full-right",
  backgroundPosition: "right",
  sectionProtection: true,
}

const planActionContent = {
  title: "Dunning in action",
  action: [
    {
      svg: true,
      image: require("../../../images/plan-actions-beth.svg"),
      alt: "Beth",
      heading: "This is Beth",
      text:
        "Her payment hasn't gone through because of lack of funds on her debit card.",
      detailReverse: true,
    },
    {
      svg: true,
      image: require("../../../images/dunning-email-one.svg"),
      alt: "email one",
      heading: "A first dunning email is sent",
      text:
        "It includes a link to her invoice, and a one-click opportunity to pay",
      detailReverse: false,
    },
    {
      svg: true,
      image: require("../../../images/dunning-email-two.svg"),
      alt: "email two",
      heading: "A second dunning email is sent",
      text:
        "Custom copy can be used to make this email more firm than the last.",
      detailReverse: true,
    },
    {
      svg: true,
      image: require("../../../images/pause.svg"),
      alt: "pause",
      heading: "Beth's account is suspended",
      text:
        "Because she still hasn't paid, our business logic handles suspension of her account.",
      detailReverse: false,
    },
    {
      svg: true,
      image: require("../../../images/subsequentpayment.svg"),
      alt: "subsequent payment",
      heading: "Emma clicks your one-click reattempt button",
      text:
        "Her payment is processed successfully, getting her account back on track.",
      detailReverse: true,
    },
    {
      svg: true,
      image: require("../../../images/resume.svg"),
      alt: "resume",
      heading: "Beth's account is resumed",
      text:
        "Billsby immediately updates her account status, so she can keep using your service.",
      detailReverse: false,
    },
  ],
}

const reasonsToStayContent = {
  title: "When customers ask to leave, give them reasons to stay.",
  description: [
    {
      text:
        "At Billsby, we don't do cancellation. We do retention. Our advanced retention tools will help you to encourage customers to stay - whether providing support, offering discounts, suggesting downshifts to cheaper plans or just understanding why customers have left; Our retention tools help you to keep more customers for longer.",
    },
  ],
  list: [
    {
      text: "Offer help and support",
    },
    {
      text: "Suggest a cheaper plan",
    },
    {
      text: "Encourage delayed cancellation",
    },
    {
      text: "Offer discounts and credits",
    },
    {
      text: "Remind users of why they signed up ",
    },
    {
      text: "Collect exit reason data",
    },
  ],
  sectionReport: true,
  image: "dunning-retention-screenshot.png",
  imageName: "dunning retention screenshot",
  backgroundImage: require("../../../images/dunning-retention-screenshot.png"),
  backgroundColor: "#f3f5fb",
  textColor: "#000",
  imagePosition: "full-right",
  sectionReasonToStay: true,
}

const interactivePreviewContent = {
  title: "Try our retention tools for yourself",
  description: (
    <>
      Choose the 'Cancel Beach' button below to see our retention tools in
      action. You'll need to have a Beach account to give this one a try. If you
      don't have one, you can{" "}
      <span data-billsby-type="checkout" className="empty-link__underline">
        create a subscription
      </span>{" "}
      and then use the button below.
    </>
  ),
  preview: (
    <>
      <div className="preview interactive-preview">
        <div className="preview-container">
          <div
            className="col-2"
            style={{
              backgroundImage: `url(${require("../../../images/preview-img1@2x.png")})`,
            }}
          ></div>
          <div className="col-2 gray-bg">
            <p className="preview-text">
              Beautiful <br />
              swimwear <br />
              delivered monthly.
            </p>
            <h4 className="preview-heading">Beach</h4>
            <span
              data-billsby-type="account"
              data-billsby-action="cancelplan"
              className="btn btn-red preview-button empty-link"
            >
              Cancel Beach
            </span>
          </div>
          <div
            className="col-3"
            style={{
              backgroundImage: `url(${require("../../../images/preview-img2@2x.png")})`,
            }}
          ></div>
          <div
            className="col-3"
            style={{
              backgroundImage: `url(${require("../../../images/preview-img3@2x.png")})`,
            }}
          ></div>
          <div
            className="col-3"
            style={{
              backgroundImage: `url(${require("../../../images/preview-img4@2x.png")})`,
            }}
          ></div>
        </div>
      </div>
    </>
  ),
}

const exitReasonReportingContent = {
  title: "Exit reason reporting helps you to improve your product.",
  description: [
    {
      text:
        "When customers cancel their account, collect their exit reasons and verbatim feedback to help you improve in future. You can customize your categories to get the right insights for your business",
    },
  ],
  image: "dunning-retention-screenshot2.png",
  imageName: "dunning retention screenshot 2",
  backgroundImage: require("../../../images/dunning-retention-screenshot2.png"),
  backgroundColor: "#243f5c",
  textColor: "#fff",
  imagePosition: "full-right",
  sectionReport: true,
  sectionReport2: true,
  backgroundPosition: "right",
}

const DunningRetention = () => (
  <Layout>
    <SEO
      title="Reduce churn rate with dunning & retention services | Billsby"
      description="Easy dunning & retention services to reduce customer churn and increase revenue with our recurring billing software and advanced billing solutions | Billsby"
      url="https://www.billsby.com/product/subscribers/dunning-retention"
    />

    <TwoColumn content={bannerContent} />
    <CustomerJourney content={customerJourneyContent} />
    <TwoColumn content={dunningContent} />
    <PlanActionInAction data={planActionContent} />
    <TwoColumn content={reasonsToStayContent} />
    <Preview content={interactivePreviewContent} />
    <TwoColumn content={exitReasonReportingContent} />
  </Layout>
)

export default DunningRetention
